import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../customHooks/useFetch";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../../styles/ArticlePageStyles";
import { ArticleUrlEnd, articleUrlStart, BASE_URL } from "../../api";
import PageWrapper from "../PageWrapper";
import "../../Components/LiveTv/LiveTv.scss";
import axios from "axios";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";
import PaginationArticles from "../../Components/PaginationArticles";
import { paginateData } from "../../helpers/paginateHeper";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import { setCategoryName } from "../../redux/categories/categoriesSlice";
import { findCategoryByTitle, routes } from "../../helpers";
import { EyeIcon } from "../../Icons";
import SocialBtnGroup from "../../Components/SocialBtnGroup/SocialBtnGroup";
import { openIsJoin } from "../../redux/layout/layoutSlice";
import Link from "react-router-dom/es/Link";
import Ads from "../../Components/Ads/Ads";
import VideoSlider from "../../Components/VideoSlider";
import useFetchYT from "../../customHooks/useFetchYT";
import CategoryTitle from "../../StyleComponent/CategoryTitle";
import HotArticles from "../../Components/HotArticles/HotArticles";

const ArticlePage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [perPage, setPerPage] = useState(5);
  const [comments, setComments] = useState([]);
  const [paginationComments, setPaginationComments] = useState([]);
  const [page, setPage] = useState(1);

  const getComments = async () => {
    const { data } = await axios.get(`${BASE_URL}/comments/?post=${id}`);
    setComments(data || []);
  };

  const addedViews = async () =>
    axios(`https://newtov.co.il/wp-json/base/int-views/${id}`);

  const posts =
    localStorage.getItem("postsIDs") &&
    JSON.parse(localStorage.getItem("postsIDs"));

  const {
    data: article,
    isLoading,
    error,
  } = useFetch(articleUrlStart + id + ArticleUrlEnd);

  useEffect(() => {
    if (comments) {
      setPaginationComments(paginateData(comments, perPage));
    }
  }, [comments, perPage]);

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    !posts && localStorage.setItem("postsIDs", JSON.stringify([id]));

    if (article && posts) {
      const categoryData = findCategoryByTitle(article.category);
      dispatch(setCategoryName(categoryData));

      const isPost = posts.find(pID => pID === id);

      if (!isPost) {
        addedViews();
        localStorage.setItem("postsIDs", JSON.stringify([...posts, id]));
      }
    }
  }, [article]);

  const handleSubmit = async () => {
    const data = {
      content: comment,
      post: id,
    };

    const response = await axios.post(`${BASE_URL}/comments`, data);

    response && (await getComments());
  };

  const getCurrentItems = () => {
    if (paginationComments.length > 0) {
      return paginationComments[page - 1];
    }
    return paginationComments;
  };

  const { videos, getVideo } = useFetchYT();

  useEffect(() => {
    getVideo();
  }, []);

  if (isLoading) return <CircularProgress />;
  if (error) return <div> {error} </div>;

  const metaTitle = article.title
    .replaceAll("&quot;", "")
    .replaceAll("&#8211;", "");

  const route = routes.filter(r => r.catTitle === article.category);
  const directRoute = route[0]?.path ? route[0].path : "";

  return (
    <PageWrapper
      articleCat={article.category}
      offset={1}
      videoShow={false}
      categoryShow={false}
    >
      <MetaTags>
        <title>{`TVgram | ${metaTitle}`}</title>
      </MetaTags>

      <Grid container xs={12} md={8} item style={{ maxWidth: "100%" }}>
        <Grid item className={classes.mainWrp}>
          <Box className={classes.imgInfoWrp}>
            <CardMedia
              image={article && article.image}
              component="img"
              title={article.title}
            />

            <Typography component="p" className={classes.imgInfo}>
              מְחַבֵּר:
              <Typography component="span">{article.author}</Typography>, תמונה:{" "}
              <Typography component="span">נֶהְדָר</Typography>
            </Typography>
          </Box>

          <SocialBtnGroup
            join={false}
            withTextShare
            styleClasses={"singleBtn"}
            groupClasses={"btnGroupSingle"}
            loinCallback={() => dispatch(openIsJoin())}
          />

          <Typography
            classes={{ root: classes.titleText }}
            component="h1"
            dangerouslySetInnerHTML={{ __html: article.title }}
          />

          <Typography
            classes={{ root: classes.subtitleText }}
            component="h2"
            dangerouslySetInnerHTML={{
              __html: article?.excerpt.replace("[&hellip;]", ""),
            }}
          />

          <Box
            className={classes.contentContainer}
            dangerouslySetInnerHTML={{ __html: article.content }}
          />

          <Box className={classes.dateViewsBox}>
            <Typography className={classes.dateText}>
              {article.author} | {article.date}
            </Typography>

            <Typography className={classes.views}>
              <EyeIcon /> {article.views}
            </Typography>
          </Box>
        </Grid>

        <Grid item classes={{ root: classes.commentsBlock }}>
          <div>
            <Typography
              component="h2"
              classes={{ root: classes.commentsTitle }}
            >
              תגובות
            </Typography>
          </div>

          <Box className={classes.form}>
            <TextField
              value={comment}
              name="comment"
              fullWidth
              variant="outlined"
              size="small"
              multiline
              maxRows={5}
              className={classes.comment}
              placeholder="תגובה"
              onChange={e => setComment(e.target.value)}
            />
            <Button
              className={classes.submitBtn}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              לִשְׁלוֹחַ
            </Button>
          </Box>

          {comments?.length !== 0 && (
            <Box id={"test"} classes={{ root: classes.commentsTitlePerPage }}>
              <Select
                value={perPage}
                name="per_page"
                onChange={e => {
                  setPage(1);
                  setPerPage(e.target.value);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </Box>
          )}

          <Grid container spacing={3} className={classes.commentsGrid}>
            {getCurrentItems().map(comment => {
              return (
                <Grid item xs={12} key={comment.id}>
                  <Card>
                    <CardHeader
                      className={classes.commentHeader}
                      title={
                        <div className={classes.commentInner}>
                          <Typography component="h3">
                            {comment.author_name || "בעילום שם"}
                          </Typography>

                          <Typography
                            component="p"
                            className={classes.commentDate}
                          >
                            {moment(comment.date).format("MMM Do YY")}
                          </Typography>
                        </div>
                      }
                    />

                    <CardContent>
                      <Typography
                        className={classes.commentComment}
                        dangerouslySetInnerHTML={{
                          __html: comment.content.rendered,
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {comments?.length === 0 ? (
            <Typography style={{ marginBottom: 20 }}>אין תגובה</Typography>
          ) : (
            <PaginationArticles
              page={page}
              setPage={v => {
                setPage(v);
                document.querySelector("#test").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              count={Math.ceil(comments?.length / perPage)}
            />
          )}
        </Grid>

        <Box style={{ marginBottom: 20, width: "100%" }}>
          <SocialBtnGroup
            join={false}
            withTextShare
            styleClasses={"singleBtn"}
            loinCallback={() => dispatch(openIsJoin())}
          />
        </Box>

        <Box className={classes.goToBtnWrp}>
          <Link
            to={{
              pathname: directRoute,
              state: { catState: route[0] },
            }}
          >
            <Typography className={classes.goToBtn}>
              {article.category}חזרה לקטגוריה
            </Typography>
          </Link>

          <Link
            to={{
              pathname: "/",
              state: { catState: route[0] },
            }}
          >
            <Typography className={classes.goToBtn}>
              חזרה לעמוד הראשי
            </Typography>
          </Link>
        </Box>
      </Grid>

      <Ads hideAds={false} single />

      {videos && <VideoSlider videos={videos} isYT />}

      <HotArticles
        heading={<CategoryTitle text={"כתבות חמות"} withArrowTitle={false} />}
        bgColor={"mainSiteCategory"}
      />

      <Ads hideAds={false} single />
    </PageWrapper>
  );
};
export default ArticlePage;
