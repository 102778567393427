import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.primary.dark,
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    margin: 0,
  

  },

  line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.dark,
    color: 'white',
    textAlign: 'center',
    padding: '10px',
    margin: 0,
        [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly'
    },
  },
  link: {
    color: 'white',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  space: {
    margin: '0 30px',
          [theme.breakpoints.down('sm')]: {
        margin: '0 00px',
    },
  },
}));
