import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '@material-ui/icons/Room';

const AnyReactComponent = () => (
  <div>
    <Marker color='secondary' fontSize='large' />
  </div>
);

class Map extends Component {
  static defaultProps = {
    center: {
      lat: 32.087762052301365,
      lng: 34.802158511695254,
    },
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ width: '100%', height: '30vh' }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyAWXD9Z4kcj1dwdxU5RDlhWMPVOINMCT0A' }}
          defaultCenter={this.props.center}
          defaultZoom={16}
        >
          <AnyReactComponent
            lat={32.087762052301365}
            lng={34.802158511695254}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
