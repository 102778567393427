import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../helpers";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Desktop } from "../common/MediaQueries";

export const useStyles = makeStyles(theme => ({
  title: {
    borderRadius: "5px",
    color: "white",
    margin: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px 0 10px",
    fontSize: "1.3rem",
    fontWeight: "300",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  boxArrow: {
    display: "flex",
    alignItems: "center",
  },
}));

const CategoryTitle = ({
  children,
  text,
  name,
  withArrowTitle = true,
  color = "#000",
}) => {
  const classes = useStyles();
  const route = routes.filter(r => r.catTitle === text || r.catTitle === name);
  const directRoute = route[0]?.path ? route[0].path : "";

  return (
    <Link
      to={{
        pathname: directRoute,
        state: { catState: route[0] },
      }}
      style={{ textDecoration: "none" }}
    >
      <Typography
        className={classes.title}
        component="div"
        style={{ background: `${color}` }}
      >
        {text}
        {children && children}

        {withArrowTitle && (
          <Box className={classes.boxArrow}>
            <Desktop>{text} עוד</Desktop>

            <ArrowBackIosIcon style={{ color: "#fff", fontSize: 20 }} />
          </Box>
        )}
      </Typography>
    </Link>
  );
};
export default CategoryTitle;
