import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  postWrp: {
    margin: "5px 0 0 0",
  },
  mainSiteCategory: {
    marginBottom: 15,
    padding: 0,

    "& > div > a": {
      color: "#fff",
      "&:hover": {
        filter: "grayscale(0.3)",
      },
    },
    "& > div:nth-child(even) > a": {
      background: "#003d79",
    },
    "& > div:nth-child(odd) > a": {
      background: "#1a81c6",
    },
  },
  secondarySiteCategory: {
    marginBottom: 15,
    padding: 0,

    "& > div > a": {
      color: "#fff",
      "&:hover": {
        filter: "grayscale(0.3)",
      },
    },
    "& > div:nth-child(2) > a, & > div:nth-child(3) > a": {
      background: "#003d79",
    },
    "& > div:nth-child(4) > a": {
      background: "#1a81c6",
    },
    "& > div:nth-child(5) > a, & > div:nth-child(6) > a": {
      background: "#003d79",
    },
    "& > div:nth-child(7) > a": {
      background: "#1a81c6",
    },
  },
  darkSiteCategory: {
    marginBottom: 15,
    padding: 0,

    "& > div > a": {
      color: "#fff",
      background: "#003d79",
      "&:hover": {
        background: "#003d79",
        filter: "grayscale(0.3)",
      },
    },
  },
  articleContainer: {
    width: "100%",
    padding: 0,
    marginBottom: 5,
    alignItems: "center",
    justifyContent: "center",

    "&:hover": {
      "& $title": {
        textDecoration: "underline",
      },
    },
  },
  cardMediaGrid: {
    height: 140,
    width: 120,
  },
  cardImage: {
    objectFit: "cover",
    width: 120,
    height: "100%",
    borderRadius: "0 5px 5px 0",
  },
  textContainer: {
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  emptyMsg: {
    margin: "50px 0",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 600,
  },
  searchMsg: {
    margin: "20px auto",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 600,
  },
}));
