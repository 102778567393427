import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isJoinModal: false,
};

const { reducer, actions } = createSlice({
  name: "LAYOUT",
  initialState,
  reducers: {
    toggleIsJoin: state => {
      state.isJoinModal = !state.isJoinModals;
    },
    openIsJoin: state => {
      state.isJoinModal = true;
    },
    closeIsJoin: state => {
      state.isJoinModal = false;
    },
  },
});

export const { toggleIsJoin, openIsJoin, closeIsJoin } = actions;

export default reducer;
