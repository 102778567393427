import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";
import Navbar from "../../Components/Navbar";
import AdsBanner from "../../Components/AdsBanner";
import tvgramLogo from "../../images/logo.png";
import { Mobile } from "../../common/MediaQueries";
import SocialBtnGroup from "../SocialBtnGroup/SocialBtnGroup";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import DrawerMenu from "../Navbar/DrawerMenu";
import { getStoreCurrentCategory } from "../../redux/categories/categoriesSelectors";
import { useDispatch, useSelector } from "react-redux";
import { openIsJoin } from "../../redux/layout/layoutSlice";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    padding: "0 5px",
    background: "#1a81c6",

    "& a": {
      display: "block",
      margin: "0 auto",
    },
  },
  linksContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  bannerSpace: {
    marginTop: "0px",
    maxWidth: "100%",
  },
  btnHeader: {
    background: theme.palette.primary.main,
    color: colors.white,
    margin: 4,

    "& svg": {
      fill: colors.white,
      width: 20,
    },
  },
  headerBanner: {
    margin: "10px 0",
    maxWidth: "100%",

    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
  },
  topWrp: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  share: {
    padding: 0,
  },
  upload: {
    width: "15px",
    height: "15px",
  },
  backdrop: {
    zIndex: 1,
  },
  icon: {
    width: 40,
    height: 40,
  },
  iconContainer: {
    background: colors.white,
    width: "100%",
    height: "auto",
    marginTop: "auto",
    padding: "15px 5px 20px",
    borderRadius: "10px 10px 0 0",
    boxSizing: "border-box",

    "& p": {
      textAlign: "center",
    },
  },
  iconWrp: {
    width: "95%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  iconButton: {
    padding: 8,
  },
  wrpInput: {
    maxWidth: 400,
    "& p": {
      color: "#fff !important",
    },
  },
  link: {
    textAlign: "right",
    color: colors.white,
    fontWeight: 600,
    fontSize: 14,
  },
  logotypeWrp: {
    display: "flex",
    alignItems: "center",

    "& img": {
      display: "block",
      margin: "0 auto",
    },
  },
  logotype: {
    width: "80%",
    height: "auto",

    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
}));

const Header = () => {
  const { location, push, length } = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isShowCatTitle, setIsShowCatTitle] = useState(false);
  const query = queryString.parse(location.search);

  const currentCategory = useSelector(getStoreCurrentCategory);

  const [searchValue, setSearchValue] = useState(query?.search || "");

  const onSearch = async e => {
    e.preventDefault();
    if (searchValue.length < 3) return;
    push(`/search?page=1&search=${searchValue}`);
  };

  useEffect(() => {
    setIsShowCatTitle(location.pathname.includes("articles"));
  }, [location, location.pathname, length, params]);

  return (
    <div className={classes.bannerSpace}>
      <AdsBanner className={classes.headerBanner} />

      <Grid classes={{ root: classes.logo }} container item xs={12}>
        <Grid item xs={2}>
          <Mobile>
            <DrawerMenu />

            {currentCategory && isShowCatTitle && (
              <Link className={classes.link} to={currentCategory.path}>
                {currentCategory.catTitle}
              </Link>
            )}
          </Mobile>
        </Grid>

        <Grid item xs={5} className={classes.logotypeWrp}>
          <Link to={"/"}>
            <img
              className={classes.logotype}
              src={tvgramLogo}
              alt="tvgram-banner"
            />
          </Link>
        </Grid>

        <Grid container item xs={5} className={classes.topWrp}>
          <Mobile>
            <Grid item xs={12}>
              <SocialBtnGroup loinCallback={() => dispatch(openIsJoin())} />
            </Grid>
          </Mobile>

          <Grid item xs={12} style={{ overflow: "hidden" }}>
            <form style={{ position: "relative" }} onSubmit={onSearch}>
              <input
                className="search_input"
                value={searchValue}
                onChange={({ target }) => setSearchValue(target.value)}
                placeholder={"לחפש"}
              />
              {searchValue.length < 3 && searchValue && (
                <span>3 תווים לפחות</span>
              )}
            </form>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Navbar />
      </Grid>
    </div>
  );
};
export default Header;
