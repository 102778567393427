import { makeStyles } from "@material-ui/core";
import colors from "./colors";

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    borderRadius: "5px",
    padding: 0,
    margin: 0,
  },
  mainWrp: {
    maxWidth: "100%",
  },
  imgInfoWrp: {
    paddingTop: 20,
  },
  articleContainer: {
    margin: "0 auto",
    padding: "15px 0 0 10px",

    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("959")]: {
      padding: "0px 5px 0 5px",
      width: "100%",
    },
  },
  imgInfo: {
    margin: "5px 0 10px",
    fontSize: 13,
    color: theme.palette.text.secondary,

    "& span": {
      fontSize: 13,
      fontStyle: "italic",
    },
  },
  contentContainer: {
    width: "100%",

    "& p": {
      fontSize: "1.125rem",
      fontFamily: "Assistant, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
      overflowWrap: "break-word",
    },
  },
  categoryText: {
    color: colors.red,
    fontSize: "1rem",
    fontWeight: "800",
  },
  dateText: {
    fontSize: "0.9rem",
    margin: "5px 0",
    fontWeight: "400",
  },
  dateViewsBox: {
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    width: "100%",
    fontSize: "2.5rem",
    fontWeight: "800",
  },
  subtitleText: {
    width: "100%",
    fontSize: "1.3rem",
    fontWeight: "500",
    lineHeight: "1.2",
  },
  contentText: {
    fontSize: "1rem",
    fontWeight: "400",
  },
  commentsBlock: {
    width: "100%",
    marginBottom: 20,
  },
  views: {
    marginRight: 20,
    display: "inline-flex",
    alignItems: "center",
    fontSize: 18,

    "& svg": {
      marginLeft: 5,
    },
  },
  form: {
    border: `1px solid ${colors.black}`,
    padding: "25px 15px",
    borderRadius: 15,
  },
  comment: {
    marginTop: 15,
    padding: 5,
  },
  submitBtn: {
    marginTop: 15,
  },
  commentsTitlePerPage: {
    margin: "50px 0 25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commentsTitle: {
    fontSize: "2rem",
    fontWeight: "700",
  },
  commentsGrid: {
    marginBottom: 30,
  },
  commentHeader: {
    background: theme.palette.primary.main,
    color: colors.white,
  },
  commentInner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commentDate: {
    fontWeight: 300,
    fontSize: 15,
  },
  commentComment: {
    color: theme.palette.text.secondary,
  },
  goToBtnWrp: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 10,
  },
  goToBtn: {
    padding: "5px 10px",
    background: theme.palette.primary.main,
    color: colors.white,
    textAlign: "center",
    borderRadius: 5,
    fontSize: 19,

    "&:not(lastChild)": {
      marginBottom: 15,
    },
  },
}));
