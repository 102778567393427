import React from "react";
import { CardMedia, Grid } from "@material-ui/core";
import { useStyles } from "./adsStyles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ads, adsSingle } from "../../helpers";
import "./Ads.scss";

const Ads = ({ hideAds, single = false, multi = false }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  if (hideAds && matches) return "";
  return (
    <Grid className={classes.adsContainer} item container xs={12} md={5}>
      {single && (
        <Grid item className={"ad3"} xs={12}>
          <CardMedia
            className={classes.ads}
            image={adsSingle.image}
            title="ADS"
          />
        </Grid>
      )}

      {multi &&
        ads.map((article, idx) => (
          <Grid
            item
            key={idx}
            className={idx === 0 ? "ad1" : idx === 1 ? "ad2" : "ad3"}
            xs={idx === 2 ? 12 : 6}
            md={idx === 0 ? 12 : 6}
          >
            <CardMedia
              className={classes.ads}
              image={article.image}
              title="ADS"
            />
          </Grid>
        ))}
    </Grid>
  );
};
export default Ads;
