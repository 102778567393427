import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import Categories from "../../Components/Categories";
import MainArticle from "../../Components/MainArticle";
import AdsBanner from "../../Components/AdsBanner";
import Ads from "../../Components/Ads/Ads";
import { CategoryGrid } from "../../StyleComponent/CategoryGrid";
import ArticlesSlider from "../../Components/ArticlesSlider";
import CategoryTitle from "../../StyleComponent/CategoryTitle";
import { categoriesArticlesUrl } from "../../api";
import { useStyles } from "./HomePageStyles";
import { Desktop } from "../../common/MediaQueries";
import { HotLinks } from "../../Components/HotLinks";
import MetaTags from "react-meta-tags";
import VideoSlider from "../../Components/VideoSlider";
import useFetchYT from "../../customHooks/useFetchYT";
import HotArticles from "../../Components/HotArticles/HotArticles";

const Home = () => {
  const classes = useStyles();

  const { videos, getVideo } = useFetchYT();

  useEffect(() => {
    getVideo();
  }, []);

  return (
    <div style={{ margin: "0 auto" }}>
      <MetaTags>
        <title>{`TVgram | אתר החדשות`}</title>
      </MetaTags>

      <Grid container item xs={12} lg={9} classes={{ root: classes.root }}>
        <Desktop>
          <Grid classes={{ root: classes.gridBlock }} item xs={12}>
            <ArticlesSlider />
          </Grid>
        </Desktop>

        <Grid classes={classes.mainArticleContainer} container item xs={12}>
          <Grid item xs={12} md={8} className={classes.newsContainer}>
            <MainArticle articleApi={"1"} />

            <Categories
              heading={<CategoryTitle text={"חדשות"} />}
              bgColor={"mainSiteCategory"}
              catNum={"1"}
              hotLinks={<HotLinks />}
              catApi={categoriesArticlesUrl}
              md={12}
              offset={1}
            />

            <AdsBanner />

            {videos && <VideoSlider videos={videos} isYT />}
          </Grid>

          {/*<Grid item xs={12} md={4}>*/}
          {/*  <LiveTv />*/}
          {/*</Grid>*/}
        </Grid>

        <CategoryGrid>
          <Ads hideAds={false} single />

          <Categories
            heading={<CategoryTitle text={"ספורט"} />}
            catNum={"5"}
            bgColor={"mainSiteCategory"}
            space="0 20px 0 0"
            catApi={categoriesArticlesUrl}
            md={7}
          />
        </CategoryGrid>

        <AdsBanner />

        <CategoryGrid>
          <Categories
            heading={<CategoryTitle text={"כלכלה"} />}
            catNum={"12"}
            bgColor={"mainSiteCategory"}
            catApi={categoriesArticlesUrl}
            space="0 0px 0 20px"
            md={7}
          />

          <Ads hideAds={false} />
        </CategoryGrid>

        <CategoryGrid>
          <Ads hideAds={true} />

          <Categories
            heading={<CategoryTitle text={"בידור ופנאי"} />}
            catApi={categoriesArticlesUrl}
            bgColor={"mainSiteCategory"}
            catNum={"38"}
            space="0 20px 0 0"
            md={7}
          />
        </CategoryGrid>

        <AdsBanner />

        <CategoryGrid>
          <Categories
            heading={<CategoryTitle text={"ניוטוב - חדשות חיוביות"} />}
            catNum={"41"}
            catApi={categoriesArticlesUrl}
            bgColor={"mainSiteCategory"}
            space="0 0px 0 20px"
            md={7}
          />

          <Ads hideAds={false} />
        </CategoryGrid>

        {/*TODO - Bad loading page with this code */}
        {/*<Grid classes={{ root: classes.gridBlock }} item xs={12}>*/}
        {/*  <CategoryTitle text="ערוץ TVGRAM" color={"#000"} />*/}
        {/*  <VideoSlider />*/}
        {/*</Grid>*/}

        <CategoryGrid>
          <Categories
            catNum={"40"}
            heading={<CategoryTitle text={"מועדון המשפיענים"} />}
            catApi={categoriesArticlesUrl}
            bgColor={"mainSiteCategory"}
            space="0 20px 0 0px"
            md={7}
          />

          <Ads hideAds={true} single />
        </CategoryGrid>

        <HotArticles
          heading={<CategoryTitle text={"כתבות חמות"} withArrowTitle={false} />}
          bgColor={"mainSiteCategory"}
        />
      </Grid>
    </div>
  );
};
export default Home;
