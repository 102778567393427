import * as React from "react";

function SvgEmail(props) {
  return (
    <svg
      width={25}
      height={25}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.776 489.776"
      {...props}
    >
      <path d="M469.075 64.488h-448.2c-10.3 0-18.8 7.5-20.5 17.3-.6 2.4-.3 322.7-.3 322.7 0 11.4 9.4 20.8 20.8 20.8h447.1c11.4 0 20.8-8.3 21.8-19.8v-320.2c.1-11.5-9.3-20.8-20.7-20.8zm-64.8 41.6l-159.8 114.4-159.8-114.4h319.6zm-363.6 278.7v-259.9l192.4 137.2c7.8 6.3 17.2 4.4 22.9 0l192.4-137.8v260.5h-407.7z" />
    </svg>
  );
}

export default SvgEmail;
