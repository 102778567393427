import { createContext, useState } from 'react';

export const ShareContext = createContext(null);

export const ShareProvider = ({ children }) => {
  const [isShared, setIsShared] = useState(false);
  return (
    <ShareContext.Provider
      value={{
        isShared,
        setIsShared,
      }}
    >
      {children}
    </ShareContext.Provider>
  );
};
