import React, { useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import colors from "../styles/colors";
import { Email, Facebook, Telegram, Youtube } from "../Icons";
import ShareIcon from "@material-ui/icons/Share";
import { ShareContext } from "../contexts/ShareContext";
import { shareFn } from "../helpers/shareApiHelper";
import WhatsApp from "../Icons/WhatsApp";

export const useStyles = makeStyles(theme => ({
  line: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    padding: "5px",
    margin: 0,
  },
  iconButton: {
    padding: "0 5px",
    transition: "transform .2s linear",

    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  shareText: {
    padding: 0,
    margin: "0 0 8px 10px",
  },

  backdrop: {
    zIndex: 1,
  },
  icon: {
    width: 30,
    height: 30,
  },
  iconContainer: {
    background: colors.white,
    width: "100%",
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    padding: 20,
    borderRadius: "10px 10px 0 0",
  },
}));

const hiddenLinks = [
  {
    url: "#",
    component: Youtube,
  },
  {
    url: "#",
    component: Email,
  },
];

export const SocialShare = () => {
  const { isShared, setIsShared } = useContext(ShareContext);

  const classes = useStyles();

  const [text, setText] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    setText(encodeURI(document.title));
    setUrl(encodeURI(window.location.href));
  }, [document.title, window.location.href]);

  return (
    <Grid className={classes.line}>
      <Typography className={classes.shareText} variant="h6">
        שיתוף:{" "}
      </Typography>

      <ShareIcon classes={{ root: classes.icon }} onClick={shareFn} />

      <Link
        target="_blank"
        classes={{ root: classes.iconButton }}
        href={`https://wa.me/?text=${text} | ${url}`}
      >
        <WhatsApp className={classes.icon} />
      </Link>

      <Link
        target="_blank"
        classes={{ root: classes.iconButton }}
        href={`https://t.me/share/url?url=${url}&text=${text}`}
      >
        <Telegram className={classes.icon} />
      </Link>

      <Link
        target="_blank"
        classes={{ root: classes.iconButton }}
        href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
      >
        <Facebook className={classes.icon} />
      </Link>

      <Backdrop
        classes={{ root: classes.backdrop }}
        onClick={() => {
          setIsShared(false);
        }}
        open={isShared}
      >
        <Box className={classes.iconContainer}>
          {hiddenLinks.map(({ component: Component, href }, idx) => {
            return (
              <Link
                target="_blank"
                key={idx}
                classes={{ root: classes.iconButton }}
                href={href}
              >
                <Component className={classes.icon} />
              </Link>
            );
          })}
        </Box>
      </Backdrop>
    </Grid>
  );
};

export default SocialShare;
