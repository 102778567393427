import React from "react";
import { Box, Button } from "@material-ui/core";
import { shareFn } from "../../helpers/shareApiHelper";
import ShareIcon from "@material-ui/icons/Share";
import { Upload } from "../../Icons";
import colors from "../../styles/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  btnGroup: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  btnGroupSingle: {
    display: "flex",
    justifyContent: "start",
  },
  btnDark: {
    background: theme.palette.primary.main,
    color: colors.white,
    padding: 4,
    margin: 2,
    minWidth: 30,

    "&:first-child": {
      marginLeft: 15,
    },

    "& svg": {
      fill: colors.white,
      width: 15,
      height: 15,
    },
  },
  btnLight: {
    background: colors.white,
    color: theme.palette.primary.main,
    margin: 4,

    "& svg": {
      fill: theme.palette.primary.main,
      width: 20,
    },

    "&:hover": {
      color: colors.white,

      "& svg": {
        color: colors.white,
        fill: colors.white,
      },
    },
  },
  singleBtn: {
    background: theme.palette.primary.main,
    color: colors.white,

    "& svg": {
      fill: colors.white,
      width: 40,
    },

    "&:hover": {
      color: colors.white,
      background: theme.palette.primary.main,
      filter: "grayscale(0.4)",

      "& svg": {
        color: colors.white,
        fill: colors.white,
      },
    },
  },
  title: {
    marginLeft: 8,
  },
}));

const SocialBtnGroup = ({
  loinCallback,
  withTextJoin = false,
  withTextShare = false,
  styleClasses = "btnDark",
  share = true,
  join = true,
  groupClasses = "multi",
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.btnGroup} ${classes[groupClasses]}`}>
      {share && (
        <Button className={classes[styleClasses]} onClick={shareFn}>
          {withTextShare && <span className={classes.title}>שתפו אותנו</span>}
          <ShareIcon />
        </Button>
      )}

      {join && (
        <Button className={classes[styleClasses]} onClick={loinCallback}>
          {withTextJoin && <span className={classes.title}>הצטרפו אלינו</span>}
          <Upload />
        </Button>
      )}
    </Box>
  );
};

export default SocialBtnGroup;
