import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 0',

    justifyContent: 'center',
  },

  buttonGrid: {
    width: '100%',
    padding: '5px',
    transition: 'all 0.2s ease',
    '&:hover': {
      transition: 'all 0.2s ease',
      transform: 'scale(0.97)',
      filter: 'brightness(1.1)',
    },

    [theme.breakpoints.down('960')]: {
      padding: '2px',
    },
  },
}));
