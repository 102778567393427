import React, { useState, useEffect } from 'react';

import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toTop: {
    zIndex: 99999,
    position: 'fixed',
    bottom: '2vh',
    transition: '0.2s all ease',
    right: '5%',
  },
  hide: {
    zIndex: 99999,
    position: 'fixed',

    bottom: '-20vh',
    transition: '0.2s all ease',

    right: '5%',
  },
}));

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);
  const classes = useStyles();
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };
  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };
  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <Fab
      className={show ? classes.toTop : classes.hide}
      color='secondary'
      size='small'
      onClick={handleClick}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
};

export default ScrollToTop;
