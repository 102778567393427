import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  moreContainer: {
    margin: '5px 0',
  },

  root: {
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardImage: {
    borderRadius: '5px',
    width: '100%',
    height: '160px',
    [theme.breakpoints.down('md')]: {
      height: '120px',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  articleContainer: {
    padding: '0px 3px 0 3px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2px 0 2px',
    },
  },
}));
