import * as React from "react";

function SvgEyeIcon(props) {
  return (
    <svg
      fill="none"
      height={24}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
      <circle cx={12} cy={12} r={3} />
    </svg>
  );
}

export default SvgEyeIcon;
