import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  contactContainer: {
    margin: '0 auto',
    padding: '15px 0 15px 10px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('960')]: {
      paddingLeft: '0px',
    },
  },
    details:{
    paddingRight:'50px',
      [theme.breakpoints.down('600')]: {
      paddingRight: '0px',
    },

    
  },
}));
