export const watchClearLocalArticlesIdList = () => {
  const clearTime =
    localStorage.getItem("clearTime") &&
    JSON.parse(localStorage.getItem("clearTime"));

  if (!clearTime) {
    localStorage.setItem("clearTime", +new Date() + 1.21e9); // Clear after 2 weeks
  } else if (clearTime && +new Date() > parseInt(clearTime)) {
    localStorage.removeItem("postsIDs");
    localStorage.removeItem("clearTime");
  }
};
