export const reportersText = `
אתר התקשורת ״TVGRAM״ הוקם במטרה לזקק עבור הגולש הישראלי הערכי רק חדשות עובדתיות ומהימנות, בשפה נקיה ועניינית, בשילוב חדשות חיוביות ומעצימות.

במיזם זה רוצים אנו להאיר זרקור ולתת במה לסיפורים אקטואליים של מעשים טובים, יחסי אנוש מרגשים, גאווה ישראלית, הישגים ופרויקטים לאומיים ומקומיים, על מנת שנוכל להתעדכן מה קורה במדינתנו בגאווה רבה.

ההגשה הייחודית שלנו מעדכנת את הגולשים בידיעות קצרות וענייניות עם אפשרות לסינון תכנים חיוביים בלבד, כולל אפשרות להעלאת תוכן גולשים, באופן עצמאי,  ליצירת שיח מפרגן ומעצים שאנשים חיוביים כל כך רוצים וצריכים.

אנו נשמח שתפרסמו ידיעות מקומיות וארציות כמו גם טורים אישיים וסרטונים משעשעים ומרגשים שיש בהם כדי להעצים את תחושת האחדות, לעודד את הרוח ולרומם את הנפש.

את הידיעות נא לשלוח לכתובת tov@newtov.co.il לפי המפרט להלן:
`;

export const textLen = `
באורך 200-400 מילים בקובץ WORD כולל שם המחבר.
`;
export const textImageUpload = `
מקורית או שיש עליה זכויות יוצרים בפורמט JPG ברוחב 1920  ברזולוציה של 72 DPI. כולל שם הצלם.
`;

export const textVideoUpload = `
באורך עד 10 דקות בפורמט MP4.
`;
export const textDescUpload = `
באורך 30-50 מילים.
`;
