import { Facebook, Instagram, Telegram, Tiktok, Youtube } from "../../Icons";

export const linksJoin = [
  {
    id: 1,
    href: "https://vm.tiktok.com/ZSeD2mpBQ/",
    component: Tiktok,
  },
  {
    id: 2,
    href: "https://youtube.com/channel/UCvjA7--gX-1GDbeFlaiET3Q",
    component: Youtube,
  },
  {
    id: 3,
    href: "https://www.facebook.com/tvgram.co.il/",
    component: Facebook,
  },
  {
    id: 4,
    href: "https://instagram.com/_tvgram?utm_medium=copy_link",
    component: Instagram,
  },
  {
    id: 5,
    href: "https://t.me/TVGramnews",
    component: Telegram,
  },
];

export const hotLinks = {
  telegram: {
    id: 1,
    href: "https://t.me/TVGRAM_UP",
  },
  wa: {
    id: 2,
    href: "https://wa.me/9720559539377",
  },
};
