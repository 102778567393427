import React from "react";
import useFetch from "../../customHooks/useFetch";
import { Link } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { useStyles } from "./MainArticleStyles";
import { mainArticleUrl } from "../../api";

const MainArticle = ({ articleApi = "" }) => {
  const classes = useStyles();

  const {
    data: article,
    isLoading,
    error,
  } = useFetch(mainArticleUrl + articleApi);

  if (isLoading) return <CircularProgress />;
  if (error) return <div>{error}</div>;

  return (
    <Grid className={classes.mainContainer} container item xs={12}>
      <Grid item xs={12} md={6} className={classes.gridButton}>
        <Button
          component={Link}
          to={{
            pathname: `/articles/${article[0].id}`,
            state: { category: article[0].category || "sport" },
          }}
        >
          <CardContent className={classes.mainCard}>
            <Typography
              className={classes.titleText}
              component="h1"
              variant="h2"
            >
              {article[0].title}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              className="max-text-7-4-2"
              dangerouslySetInnerHTML={{ __html: article[0].excerpt }}
            />

            <Typography variant="subtitle1">
              {article[0].author} | {article[0].date}
            </Typography>
          </CardContent>
        </Button>
      </Grid>

      <Grid item xs={12} md={6}>
        <CardMedia
          component="img"
          className={classes.mainArticleImage}
          image={article && article[0].image}
          title={article[0].title}
        />
      </Grid>
    </Grid>
  );
};
export default MainArticle;
