import React from "react";
import { Pagination } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { useStyles } from "./PaginationArticlesStyles";

const PaginationArticles = ({ page, setPage, count = 100 }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container item xs={12}>
      <Pagination
        onChange={(event, value) => setPage(value)}
        count={count}
        page={page}
        size="small"
        color="primary"
        shape="rounded"
      />
    </Grid>
  );
};
export default PaginationArticles;
