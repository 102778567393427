import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '10px',
  },
  root: {
    padding: 0,
    marginBottom: '10px',
    maxWidth: '100%',
    backgroundColor: 'rgba(1, 5, 99, 0.03)',
    overflowX: 'hidden',
  },

  slickItem: {
    direction: 'rtl',
    textAlign: 'right',
    position: 'relative',
    borderLeft: '1px dashed',
    borderColor: 'rgba(1,5,99,0.1)',
    [theme.breakpoints.down('md')]: {
      borderLeft: '1px dashed rgba(1,5,99,0.1)',
    },
  },
}));
