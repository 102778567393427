export const aboutText = `

אתר התקשורת ״TVGRAM״ הוקם במטרה לזקק עבור הגולש הישראלי הערכי רק חדשות עובדתיות ומהימנות, בשפה נקיה ועניינית, בשילוב חדשות חיוביות ומעצימות.

ההגשה הייחודית שלנו מעדכנת את הגולשים בידיעות קצרות ואוביקטיביות ללא אינטרס פוליטי, לצד דיווח יום יומי על ידיעות טובות, מרגשות ומפרגנות.

אנו מאמינים שלתוכן שכזה יש השפעה חיובית על מצב רוחנו ומערכות היחסים שלנו במשפחה, בעבודה ובחברה, ובזאת אנו מבקשים לעודד יצירת שיח מאחד מפרגן ומעצים.

במיזם זה רוצים אנו להאיר זרקור ולתת במה לסיפורים אקטואליים של מעשים טובים, יחסי אנוש מרגשים, גאווה ישראלית, הישגים ופרויקטים לאומיים ומקומיים, על מנת שנוכל להתעדכן מה קורה במדינתנו בגאווה רבה.

החזון שלנו מתבטא בשאיפה הבלתי מתפשרת להאיר את עיני ילדנו ואולי אף את עיננו אנו ולהחזיר לקדמת חיינו ערכים נאורים שנראה כי נדחקו לשולי החדשות אם בכלל.

ב״TVGRAM״ תמצאו קהילה השותפה ליוזמה פורצת דרך לתקשורת א-פוליטית  מאחדת, מפרגנת ומעצימה.

אנו מבטיחים חווית צריכת תוכן וניהול שיח שטרם הכרת!
`;
