import React from 'react';
import { CardMedia, makeStyles } from '@material-ui/core';
import './AdsBanner.scss';
import adsBanner from '../../images/ads/adsBanner.png';
const useStyles = makeStyles((theme) => ({
  media: {
    margin: '0px auto',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '95%',
    maxHeight: '150px',
    objectFit: 'contain',
  },
}));
const AdsBanner = ({ className = '' }) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <CardMedia
        component='img'
        className={classes.media}
        image={adsBanner}
        title='adsBanner'
      />
    </div>
  );
};
export default AdsBanner;
