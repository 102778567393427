import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import useFetch from "../../customHooks/useFetch";
import { useStyles } from "./CategoriesStyles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { loadMoreCatArticles } from "../../api";
import PaginationArticles from "../PaginationArticles";
import LoadingSpinner from "../../StyleComponent/LoadingSpinner";
import colors from "../../styles/colors";
import OnePost from "../OnePost/OnePost";
import { getOffset } from "../../helpers/offsetHelper";

const Categories = ({
  interest = false,
  heading: CategoryTitle,
  hotLinks: HotLinks,
  md,
  bgColor = colors.white,
  catNum = "",
  space = "0",
  offset = 0,
}) => {
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const {
    data: article,
    isLoading,
    error,
    info,
  } = useFetch(
    `${loadMoreCatArticles}categories=${catNum}&page=${page}&per_page=6${getOffset(
      offset,
      page
    )}`
  );

  const classes = useStyles();

  if (error) return <div>{error}</div>;

  return (
    <Grid
      container
      xs={12}
      md={md}
      item
      className={classes[bgColor]}
      style={{
        position: "relative",
        padding: !matches ? "0" : `${space}`,
      }}
    >
      <Grid style={{ minWidth: "100%" }} item>
        {CategoryTitle}
      </Grid>

      {article &&
        article.map(article => (
          <OnePost
            key={article.id}
            article={article}
            bgColor={bgColor}
            interest={interest}
          />
        ))}

      <PaginationArticles
        page={page}
        setPage={setPage}
        count={info?.totalPages}
      />

      {isLoading && <LoadingSpinner />}

      {HotLinks}
    </Grid>
  );
};
export default Categories;
