import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    zIndex: '10',
    width: '100%',
    height: '100%',
    minHeight: '550px',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '999999',
  },
  indeterminate: {
    animation: 'none',
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        classes={{
          root: classes.spinner,
          indeterminate: classes.indeterminate,
        }}
        variant='indeterminate'
        thickness={2}
        size={90}
        color='secondary'
      />
    </div>
  );
};
export default LoadingSpinner;
