export const shareFn = () => {
  if (navigator.share) {
    navigator
      .share({
        title: document.title,
        url: window.location.href,
      })
      .then(function () {
        console.log("Shareing successfull");
      })
      .catch(function () {
        console.log("Sharing failed");
      });
  } else {
    console.log("Sorry! Your browser does not support Web Share API");
  }
};
