import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  reportersContainer: {
    margin: '0 auto',
    padding: '15px 0 15px 40px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('960')]: {
      paddingLeft: '0px',
    },
  },
}));
