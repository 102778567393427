import { Hidden, useMediaQuery } from '@material-ui/core';

export const useIsDesktop = () =>
  useMediaQuery((theme) => theme.breakpoints.up('md'));

export const Mobile = ({ children }) => <Hidden mdUp>{children}</Hidden>;

export const Desktop = ({ children }) => <Hidden smDown>{children}</Hidden>;

export const IEMediaQuery =
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)';

export const EdgeMediaQuery = '@supports (-ms-ime-align:auto)';
