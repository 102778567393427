import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentCategory: "",
};

const { reducer, actions } = createSlice({
  name: "CATEGORIES",
  initialState,
  reducers: {
    setCategoryName: (state, { payload }) => {
      state.currentCategory = payload;
    },
  },
});

export const { setCategoryName } = actions;

export default reducer;
