import React from "react";

const FbComments = () => {
  return (
    <div className="comments-container">
      <div
        data-colorscheme="dark"
        className="fb-comments"
        data-href={window.location.href}
        data-width="100%"
        data-numposts="5"
      />
      <div
        className="fb-like"
        data-href={window.location.href}
        data-width=""
        data-layout="standard"
        data-action="like"
        data-size="small"
        data-share="false"
      />
    </div>
  );
};

export default FbComments;
