import React from "react";
import { POPULAR_POSTS_URL } from "../../api";
import useFetch from "../../customHooks/useFetch";
import { Grid } from "@material-ui/core";
import LoadingSpinner from "../../StyleComponent/LoadingSpinner";
import { useStyles } from "../Categories/CategoriesStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import OnePost from "../OnePost/OnePost";

const HotArticles = ({
  heading: CategoryTitle,
  bgColor,
  md = 12,
  interest = false,
  space = "0",
  hour = 72,
}) => {
  const {
    data: article,
    isLoading,
    error,
  } = useFetch(`${POPULAR_POSTS_URL}&time_quantity=${hour}`, 1, true, 6);

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  if (error) return <div>{error}</div>;

  return (
    <Grid
      container
      xs={12}
      md={md}
      item
      className={classes[bgColor]}
      style={{
        position: "relative",
        padding: !matches ? "0" : `${space}`,
      }}
    >
      <Grid style={{ minWidth: "100%" }} item>
        {CategoryTitle}
      </Grid>

      {article.length > 0 &&
        article.map(article => (
          <OnePost
            key={article.id}
            article={article}
            bgColor={bgColor}
            interest={interest}
          />
        ))}

      {isLoading && <LoadingSpinner />}
    </Grid>
  );
};

export default HotArticles;
