import React from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useStyles } from "./ReportersStyles";
import {
  reportersText,
  textDescUpload,
  textImageUpload,
  textLen,
  textVideoUpload,
} from "../../helpers/texts/reportersText";
import SocialShare from "../../StyleComponent/SocialShare";
import ReactPlayer from "react-player";
import PageWrapper from "../PageWrapper";
import MetaTags from "react-meta-tags";

const AboutPage = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <PageWrapper articleCat={"חדשות"}>
      <MetaTags>
        <title>{`TVgram | עיתונאים`}</title>
      </MetaTags>

      <Grid
        container
        item
        xs={12}
        md={8}
        className={classes.reportersContainer}
      >
        <Typography gutterBottom component="h1" variant="h1">
          שלחו ידיעה / סרטון
        </Typography>
        <Typography gutterBottom variant="body1">
          {reportersText}
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
          gutterBottom
          component="h1"
        >
          ידיעות:
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold" }}
          gutterBottom
          component="h1"
        >
          טקסט <span style={{ fontWeight: "400" }}>{textLen}</span>
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold" }}
          gutterBottom
          component="h1"
        >
          תמונה <span style={{ fontWeight: "400" }}>{textImageUpload}</span>
        </Typography>

        <Typography
          variant="body1"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
          gutterBottom
          component="h1"
        >
          סרטונים:
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold" }}
          gutterBottom
          component="h1"
        >
          סרטון <span style={{ fontWeight: "400" }}>{textVideoUpload}</span>
        </Typography>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold" }}
          gutterBottom
          component="h1"
        >
          תיאור קצר <span style={{ fontWeight: "400" }}>{textDescUpload}</span>
        </Typography>
        <div style={{ margin: "10px 0 10px 0" }}>
          <SocialShare
            title={"TVGRAM"}
            url={`https://tvgram.co.il${pathname}`}
          />
        </div>
        <ReactPlayer
          url={"https://www.youtube.com/watch?v=cuUwjiZCR1Y"}
          width="100%"
          height="300px"
          playing={true}
          volume={1}
          muted={true}
          controls
        />
      </Grid>
    </PageWrapper>
  );
};
export default AboutPage;
