import React from "react";
import { Backdrop, Box, IconButton, Typography } from "@material-ui/core";
import { linksJoin } from "../../common/data/linksJoin";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 1,
  },
  icon: {
    width: 40,
    height: 40,
  },
  iconContainer: {
    background: colors.white,
    width: "100%",
    height: "auto",
    marginTop: "auto",
    padding: "15px 5px 20px",
    borderRadius: "10px 10px 0 0",
    boxSizing: "border-box",
    "& p": {
      textAlign: "center",
      marginBottom: 25,
    },
  },
  iconWrp: {
    width: "95%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  iconButton: {
    padding: 8,
  },
}));

const JoinBackdrop = ({ isShow, callback }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isShow} onClick={callback}>
      <Box className={classes.iconContainer}>
        <Typography component="p">הצטרפו אלינו</Typography>

        <Box className={classes.iconWrp}>
          {linksJoin.map(({ component: Component, href }, id) => {
            return (
              <IconButton
                disableRipple
                key={id}
                classes={{ root: classes.iconButton }}
              >
                <a target="_blank" rel="noopener" rel="noreferrer" href={href}>
                  <Component className={classes.icon} />
                </a>
              </IconButton>
            );
          })}
        </Box>
      </Box>
    </Backdrop>
  );
};

export default JoinBackdrop;
