import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '5px',
    padding: 0,
    margin: 0,
  },
  image: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  imageTextContainer: {
    textAlign: 'right',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.75)',
    position: 'absolute',
    bottom: '0',
    padding: '5px 0',

    width: '100%',
  },
  imageText: {
    fontSize: '0.3rem',
  },

  buttonGrid: {
    width: '100%',
    padding: '5px',
    transition: 'all 0.2s ease',
    '&:hover': {
      transition: 'all 0.2s ease',
      transform: 'scale(0.97)',
      filter: 'brightness(1.1)',
    },
    infinite: {},
    [theme.breakpoints.down('960')]: {
      padding: '2px',
    },
  },
}));
