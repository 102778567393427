import React, { useState } from "react";
import {
  CardMedia,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import logo from "../../images/logo.png";
import { Spin as Hamburger } from "hamburger-react";
import { NavLink } from "react-router-dom";
import { categories, routes } from "../../helpers";

const useStyles = makeStyles(theme => ({
  root: {
    height: "auto",
    position: "relative",
  },
  item: {
    display: "flex !important",
    justifyContent: "space-between",
    textAlign: "right",
    padding: "0 10px 0 0",

    "&.active-nav-menu .circle ": {
      background: "#ff0000",
    },

    "& div": {
      margin: 0,
    },

    "& div span": {
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "8px 0 8px 40px",
    },
  },
  listItem: {
    // display: "flex",
    // justifyContent: "space-between",
  },
  circle: {
    display: "block",
    height: 10,
    width: 10,
    margin: "0 30px",
    borderRadius: "50%",
    background: "transparent",
  },
  image: {
    height: "auto",
    width: "120px",
    margin: "0 auto 20px",
  },
  logoText: {
    textAlign: "left",
    position: "absolute",
    margin: "10px",
    padding: 0,
    left: 0,
    color: "#e65261",
    justifySelf: "center",
  },
}));

const DrawerMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();

  return (
    <>
      <div>
        <Hamburger
          rounded
          toggled={openDrawer}
          onToggle={() => setOpenDrawer(!openDrawer)}
          color="#fff"
          direction="right"
          size={26}
          distance="sm"
        />
      </div>

      <Drawer
        PaperProps={{ elevation: 9, background: "red" }}
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
        open={openDrawer}
        variant="persistent"
      >
        <Hamburger
          rounded
          toggled={openDrawer}
          onToggle={() => setOpenDrawer(!openDrawer)}
          color="#000"
          direction="right"
          size={26}
          distance="sm"
        />

        <CardMedia
          className={classes.image}
          component="img"
          image={logo}
          title="logo"
        />

        <List classes={{ root: classes.root }}>
          {categories.map((category, idx) => {
            return (
              <ListItem
                component={NavLink}
                to={{
                  pathname: `${routes[idx].path}`,
                  state: { catState: routes[idx] },
                }}
                onClick={() => setOpenDrawer(false)}
                key={idx}
                className={classes.item}
                divider
                button
                activeClassName={"active-nav-menu"}
              >
                <ListItemText classes={{ primary: classes.listItem }}>
                  {category}
                </ListItemText>

                <span className={`${classes.circle} circle`} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
