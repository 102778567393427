import React from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useStyles } from "./ContactPageStyles";
import SocialShare from "../../StyleComponent/SocialShare";
import PageWrapper from "../PageWrapper";
import Mail from "@material-ui/icons/MailOutline";
import Phone from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/AlternateEmail";
import Map from "./Map";
import Email from "./Email";
import MetaTags from "react-meta-tags";

const ContactPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <PageWrapper articleCat={"חדשות"}>
      <MetaTags>
        <title>{`TVgram | אנשי קשר`}</title>
      </MetaTags>

      <Grid container item xs={12} md={8} className={classes.contactContainer}>
        <Typography color="primary" gutterBottom component="h1" variant="h1">
          צרו קשר
        </Typography>

        <Map />

        <div style={{ margin: "10px 0 10px 0" }}>
          <SocialShare
            title={"TVGRAM"}
            url={`https://tvgram.co.il${pathname}`}
          />
        </div>
        <Grid container>
          <Grid xs={12} sm={6} item style={{ paddingLeft: "10px" }}>
            <Typography
              color="secondary"
              gutterBottom
              component="h3"
              variant="h3"
            >
              מוזמנים ליצור קשר
            </Typography>
            <Email />
          </Grid>
          <Grid xs={12} sm={6} item className={classes.details}>
            <Typography
              color="secondary"
              gutterBottom
              component="h3"
              variant="h3"
            >
              פרטי התקשרות:
            </Typography>
            <Grid container>
              <Mail fontSize={"medium"} />
              <Typography
                style={{ margin: "-2px 10px 10px 10px" }}
                gutterBottom
              >
                אהליאב 10, רמת גן
              </Typography>
            </Grid>
            <Grid container>
              <Phone color="secondary" fontSize={"medium"} />
              <Typography
                style={{ margin: "-2px 10px 10px 10px" }}
                gutterBottom
              >
                052-774-0235
              </Typography>
            </Grid>
            <Grid container>
              <EmailIcon color="primary" fontSize={"medium"} />
              <Typography
                style={{ margin: "-2px 10px 10px 10px" }}
                gutterBottom
              >
                Contact@tvgram.co.il
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  );
};
export default ContactPage;
