import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import { useStyles } from "./Pages/HomePage/HomePageStyles";
import { Grid, ThemeProvider } from "@material-ui/core";
import { theme } from "./styles/theme";
import "./App.css";
import Header from "./Components/Header";
import CategoryPage from "./Pages/CategoryPage";
import ReportersPage from "./Pages/ReportersPage";
import ContactPage from "./Pages/ContactPage";
import AboutPage from "./Pages/AboutPage";
import { responsiveFontSizes } from "@material-ui/core/styles";
import Footer from "./Components/Footer";
import CategoryTitle from "./StyleComponent/CategoryTitle";
import AdsBanner from "./Components/AdsBanner";
import Mosaic from "./Components/Mosaic";
import RouterToTop from "./customHooks/RouterToTop";
import ArticlePage from "./Pages/ArticlePage";
import SearchPage from "./Pages/SearchPage";
import HotLinks from "./Components/HotLinks/HotLinks";
import store from "./redux/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import { watchClearLocalArticlesIdList } from "./helpers/clearLocalStorageHelper";
import { closeIsJoin } from "./redux/layout/layoutSlice";
import JoinBackdrop from "./Components/JoinBackdrop/JoinBackdrop";
import { getStoreIsJoinModal } from "./redux/layout/layoutSelectors";

let themeResponsiveFont = responsiveFontSizes(theme);

const AppRouter = () => {
  const dispatch = useDispatch();
  watchClearLocalArticlesIdList();
  const classes = useStyles();

  const isJoinModal = useSelector(getStoreIsJoinModal);

  return (
    <Provider store={store.store}>
      <ThemeProvider theme={themeResponsiveFont}>
        <Router>
          <RouterToTop />
          <Header />

          <div style={{ padding: "0 10px", marginBottom: "10px" }}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/search" component={SearchPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/reporters" component={ReportersPage} />
              <Route path="/articles/:id" component={ArticlePage} />
              <Route
                path="/categories/:categoryName"
                component={CategoryPage}
              />
            </Switch>

            <Grid item className={classes.root} lg={9} xs={12} container>
              <Grid className={classes.root} container>
                <AdsBanner className="header-banner" />

                <Grid style={{ minWidth: "100%", margin: "5px auto" }} item>
                  <CategoryTitle
                    text={"יעניין אותך גם"}
                    withArrowTitle={false}
                  />
                </Grid>

                <Mosaic />
              </Grid>

              {/*TODO - Bad loading page with this code */}
              {/*<Grid*/}
              {/*  item*/}
              {/*  style={{ margin: "5px auto" }}*/}
              {/*  className={classes.root}*/}
              {/*  lg={9}*/}
              {/*  xs={12}*/}
              {/*  container*/}
              {/*>*/}
              {/*  <Grid style={{ minWidth: "100%" }}>*/}
              {/*    <CategoryTitle text="ערוץ TVGRAM" color={"#000"} />*/}
              {/*  </Grid>*/}

              {/*<Grid item style={{ minWidth: "100%" }}>*/}
              {/*  <VideoSlider />*/}
              {/*</Grid>*/}
              {/*</Grid>*/}
            </Grid>
          </div>

          <HotLinks />

          <JoinBackdrop
            isShow={isJoinModal}
            callback={() => dispatch(closeIsJoin())}
          />

          <Footer />
        </Router>
      </ThemeProvider>
    </Provider>
  );
};
export default AppRouter;
