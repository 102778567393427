import * as React from "react";

function SvgUpload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 18 330 330"
      height={25}
      width={25}
      {...props}
    >
      <path d="M105.604 105.605L150 61.212v183.786c0 8.284 6.716 15 15 15s15-6.716 15-15V61.213l44.392 44.392a14.954 14.954 0 0010.606 4.394 14.95 14.95 0 0010.607-4.394c5.858-5.858 5.858-15.355 0-21.213L175.61 14.396a15.553 15.553 0 00-1.104-.998c-.166-.136-.341-.254-.51-.381-.222-.167-.439-.337-.67-.492-.207-.139-.422-.259-.635-.386-.207-.125-.41-.254-.624-.369-.217-.116-.439-.213-.661-.318-.223-.105-.441-.216-.67-.311-.214-.088-.432-.16-.649-.238-.244-.088-.485-.182-.736-.258-.216-.065-.435-.112-.652-.167-.256-.065-.51-.137-.77-.189-.25-.049-.503-.078-.755-.115-.231-.034-.46-.077-.695-.1a15.342 15.342 0 00-1.389-.07c-.03 0-.059-.004-.089-.004-.029 0-.059.004-.088.004-.464.002-.928.025-1.391.07-.229.023-.453.065-.68.098-.258.037-.516.067-.771.118-.254.05-.5.12-.749.183-.226.057-.452.107-.676.174-.241.073-.476.164-.712.249-.225.081-.452.155-.674.247-.22.091-.43.198-.644.299-.23.108-.462.211-.688.331-.204.109-.396.233-.595.351-.223.132-.447.258-.664.403-.217.145-.42.307-.629.462-.184.137-.371.264-.549.411-.365.299-.714.616-1.049.947-.016.016-.033.029-.05.045l-70 69.999c-5.857 5.858-5.858 15.355 0 21.213 5.857 5.859 15.355 5.857 21.212.001z" />
      <path d="M315 160c-8.284 0-15 6.716-15 15v115H30V175c0-8.284-6.716-15-15-15s-15 6.716-15 15v130c0 8.284 6.716 15 15 15h300c8.284 0 15-6.716 15-15V175c0-8.284-6.716-15-15-15z" />
    </svg>
  );
}

export default SvgUpload;
