import React from 'react';

const ArticleBorder = () => {
  return (
    <div
      style={{
        borderBottom: '1px dashed rgba(0,0,0,0.1)',

        width: '100%',
      }}
    />
  );
};
export default ArticleBorder;
