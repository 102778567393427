import { useEffect, useState } from "react";
import { categoryToName, convertDate } from "../helpers";
import axios from "axios";
import { ArticleUrlEnd, articleUrlStart } from "../api";

const useFetch = (url, page = "1", popular = false, popularItem = 6) => {
  const [data, setData] = useState([]);
  const [info, setInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const articles = [];

  const combineData = data => {
    data.forEach(post => {
      articles.push({
        id: post.id,
        author: post?._embedded?.author[0].name,
        excerpt: post?.excerpt?.rendered,
        title: post?.title?.rendered,
        image: post?._embedded["wp:featuredmedia"][0].link,
        slug: post?.slug,
        category: categoryToName(post?.categories[0]),
        content: post?.content?.rendered,
        date: convertDate(post?.date),
      });
    });

    setData(articles);
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const res = await axios(url);
        const { data, headers } = res;

        setInfo({
          totalPosts: Number(headers["x-wp-total"]),
          totalPages: Number(headers["x-wp-totalpages"]),
        });

        if (popular) {
          const yy = await Promise.all(
            res.data.slice(0, 6).map(async post => {
              const { data } = await axios(
                articleUrlStart + post.id + ArticleUrlEnd
              );
              return data;
            })
          );

          combineData(yy);
        }

        if (!popular) {
          if (Array.isArray(data)) {
            combineData(data);
          } else {
            setData({
              views: data.acf.views,
              id: data.id,
              author: data._embedded.author[0].name,
              excerpt: data.excerpt.rendered,
              title: data.title.rendered,
              image: data._embedded["wp:featuredmedia"][0].link,
              slug: data.slug,
              category: categoryToName(data.categories[0]),
              content: data.content.rendered,
              date: convertDate(data.date),
            });
          }
        }
      } catch (err) {
        const errData = err ? err : "Server error";
        setError(errData);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  return { data, info, isLoading, error };
};

export default useFetch;
