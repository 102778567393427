import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Button, InputLabel, TextField } from '@material-ui/core';

export default function Email() {
  const [message, setMessage] = useState({ content: false, color: '' });
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_gybqaaq',
        'email_tvgram',
        e.target,
        'user_4w6LBtWqMzzo3yhKdS4RU'
      )
      .then(
        (result) => {
          setMessage({ content: 'ההודעה נשלחה בהצלחה', color: 'green' });
        },
        (error) => {
          setMessage({ content: 'קרתה תקלה בדרך, נסה שנית', color: 'red' });
        }
      );
  }

  return (
    <>
      <form onSubmit={sendEmail} noValidate autoComplete='off'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <InputLabel color='primary'>Email</InputLabel>
          <TextField
            type='email'
            name='user_email'
            placeholder='אימייל'
            size='small'
            variant='outlined'
          />
          <InputLabel color='primary'>שם</InputLabel>
          <TextField
            type='text'
            name='user_name'
            size='small'
            placeholder='שם מלא'
            variant='outlined'
          />
          <InputLabel color='primary'>הודעה</InputLabel>

          <TextField
            minRows='2'
            multiline={true}
            placeholder='תוכן ההודעה'
            name='content'
            size='small'
            variant='outlined'
          />

          <Button
            type='submit'
            value='Send'
            style={{ margin: '10px 0' }}
            variant='contained'
            color='primary'
          >
            שלח לנו !
          </Button>
        </div>
        <div style={{ fontWeight: '700', color: message.color }}>
          {message.content}
        </div>
      </form>
    </>
  );
}
