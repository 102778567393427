import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardMedia from "@material-ui/core/CardMedia";
import { useStyles } from "./MosaicStyles";
import AdsBanner from "../AdsBanner";
import { mosaicArticlesUrl } from "../../api";
import { customizeFetchData } from "../../helpers";

const Mosaic = () => {
  const [offset, setOffset] = useState(72);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("960"));

  useEffect(() => {
    const fetchNextData = async () => {
      const response = await fetch(mosaicArticlesUrl + offset);
      const nextData = await response.json();

      const customNextData = nextData.map((item, idx) =>
        customizeFetchData(item, idx)
      );
      setData([...data, ...customNextData]);
    };

    fetchNextData();
  }, [offset]);

  return (
    <div style={{ width: "100%" }}>
      <InfiniteScroll
        style={{ overflow: "visible", width: "100%" }}
        dataLength={data && data.length} //This is important field to render the next data
        next={() => setOffset(prev => prev + 6)}
        hasMore={offset < 172 ? true : false}
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Grid container style={{ width: "100%" }}>
          {data?.map((article, idx) => (
            <div key={idx} style={{ width: "100%" }}>
              {idx > 6 && idx % 6 === 0 && (
                <AdsBanner className="ads-mosaic" key={idx} />
              )}
              <Grid
                component={Link}
                to={`/articles/${article.id}`}
                className={classes.buttonGrid}
                md={article.mdIndex ? 6 : 4}
                xs={article.xsIndex ? 12 : 6}
                item
              >
                <Box style={{ height: !matches ? "240px" : "300px" }}>
                  <CardMedia
                    className={classes.image}
                    image={article.image}
                    title="mosaic images"
                  >
                    <div className={classes.imageTextContainer}>
                      <Typography
                        className="max-text-2-1"
                        dangerouslySetInnerHTML={{ __html: article.title }}
                      />
                    </div>
                  </CardMedia>
                </Box>
              </Grid>
            </div>
          ))}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};
export default Mosaic;
