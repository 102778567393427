import React from "react";
import Slider from "react-slick";
import { nanoid } from "nanoid";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./VideoSlider.scss";
import ReactPlayer from "react-player/facebook";
import { Typography } from "@material-ui/core";
import YouTube from "react-youtube";

const VideoSlider = ({ videos, isYT = false, isFB = false }) => {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: false,
    focusOnSelect: true,
    rtl: true,
    arrows: true,
    dots: false,
    className: "slick-container",
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          className: "slick-container",
          arrows: true,
        },
      },
      {
        breakpoint: 599,
        settings: {
          className: "slick-container",
          vertical: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          infinite: true,
        },
      },
    ],
  };

  const opts = {
    height: "auto",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <Slider {...settings}>
        {isYT &&
          videos.map(v => {
            return (
              <YouTube
                key={v.snippet.resourceId.videoId}
                videoId={v.snippet.resourceId.videoId}
                opts={opts}
              />
            );
          })}

        {isFB &
          videos.map(({ video, id, title }) => (
            <div key={id}>
              <li className="slick-video-wrapper">
                <ReactPlayer
                  url={video}
                  width="100%"
                  height="100%"
                  config={{
                    facebook: {
                      appId: "4592693550845209",
                      playerId: nanoid(),
                    },
                  }}
                  playing={false}
                  muted
                  style={{
                    margin: "0 auto",
                  }}
                  controls
                />
              </li>
              <Typography variant="subtitle1" className="video-text">
                {title}
              </Typography>
            </div>
          ))}
      </Slider>
    </>
  );
};

export default VideoSlider;
