import * as React from "react";

function SvgTelegram(props) {
  return (
    <svg
      width={15}
      height={15}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.008 512.008"
      {...props}
    >
      <path
        fill="#e9ebef"
        d="M192.007 320.004l173.594 81.912 130.765-386.39z"
      />
      <path fill="#919cb0" d="M192.007 320.004v179.217l87.296-140.45z" />
      <path
        fill="#d3d7df"
        d="M14.232 320.004h177.775L491.382 15.526 14.446 299.029z"
      />
      <path
        d="M502.552 5.738A25.606 25.606 0 00486.398.004a25.545 25.545 0 00-13.346 3.755l-460.8 281.6C2.464 291.341-2.152 303.1.962 314.133a25.601 25.601 0 0024.644 18.671h153.6v166.4c0 5.726 3.789 10.752 9.284 12.297a12.24 12.24 0 003.516.503c4.335 0 8.491-2.227 10.871-6.05l81.135-130.526 62.942 31.471a25.573 25.573 0 0011.452 2.705 25.592 25.592 0 0024.106-16.99l128-358.4a25.593 25.593 0 00-7.96-28.476zM186.708 307.204H25.607l414.26-253.158-253.159 253.158zm18.099 147.174V335.825l56.218 28.109-56.218 90.444zm153.6-70.374l-141.534-70.767L476.347 53.764l-117.94 330.24z"
        fill="#573a32"
      />
    </svg>
  );
}

export default SvgTelegram;
