import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg
      width={15}
      height={15}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M134.941 272.691h56.123v231.051a8.256 8.256 0 008.258 8.258h95.159a8.256 8.256 0 008.258-8.258V273.78h64.519a8.26 8.26 0 008.204-7.315l9.799-85.061a8.259 8.259 0 00-8.202-9.203h-74.316V118.88c0-16.073 8.654-24.224 25.726-24.224h48.59a8.258 8.258 0 008.258-8.258V8.319a8.256 8.256 0 00-8.258-8.258h-66.965A65.863 65.863 0 00307.027 0c-11.619 0-52.006 2.281-83.909 31.63-35.348 32.524-30.434 71.465-29.26 78.217v62.352H134.94a8.256 8.256 0 00-8.258 8.258v83.975a8.26 8.26 0 008.259 8.259z"
        fill="#385c8e"
      />
    </svg>
  );
}

export default SvgFacebook;
