import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridBlock: {
    margin: '10px 0px',
  },
}));

export const CategoryGrid = ({ children }) => {
  const classes = useStyles();
  return (
    <Grid item container classes={{ root: classes.gridBlock }} xs={12}>
      {children}
    </Grid>
  );
};
