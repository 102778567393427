import {
  AppBar,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import ScrollToTop from "../../customHooks/ScrollToTop";
import { categories, routes } from "../../helpers";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  appbar: {
    backgroundColor: theme.palette.primary.main,
    margin: "10px auto 0px auto",
    boxShadow: "none",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 0,
    padding: 0,
    paddingBottom: 0,
    paddingTop: 0,

    zIndex: 1,
  },
  tabs: {
    minHeight: 0,
    padding: 0,
  },
  tab: {
    color: "white",
    minWidth: "120px",
    fontSize: "1rem",
    height: "fit-content",
    fontWeight: "400",
    minHeight: 0,

    paddingBottom: "3px",
    paddingTop: "3px",
    transition: "all 0.2s ease",
    opacity: 0.9,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      transition: "all 0.3s ease",
    },
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: "2px",
    marginBottom: "0px",
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [active, setActive] = useState(0);
  const theme = useTheme();

  const handleClickTab = (e, newValue) => {
    setActive(newValue);
  };

  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar
      id="back-to-top-anchor"
      className={classes.appbar}
      center="true"
      position={isMatch ? "fixed" : "sticky"}
    >
      <ScrollToTop showBelow={250} />

      {!isMatch && (
        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          className={classes.tabs}
          onChange={handleClickTab}
          value={active}
        >
          {categories.map((category, idx) => (
            <Tab
              component={Link}
              to={{
                pathname: `/${routes[idx].path}`,
                state: { catState: routes[idx] },
              }}
              key={idx}
              className={classes.tab}
              label={category}
            />
          ))}
        </Tabs>
      )}
    </AppBar>
  );
};
export default Navbar;
