import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px;',
    padding: '5px',
    margin: '0 0 10px 0',
    borderRadius: '5px 0 0 5px',
  },
  mainArticleImage: {
    height: '100%',
    maxHeight: '400px',
    borderRadius: '5px 0 0 5px',
  },
  mainCard: {
    padding: '0 0 0 40px',
    paddingBottom: '0',
    [theme.breakpoints.down('960')]: {
      paddingLeft: '0px',
    },
  },
  titleText: {},
  gridButton: {
    '&:hover': {
      '& $titleText': {
        textDecoration: 'underline',
      },
    },
  },
}));
