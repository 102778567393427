export const articleSliderUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&per_page=6&categories=1,5,12,38,40,41";

export const mainArticleUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&per_page=1&categories=";

export const subMainArticlesUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&offset=1&per_page=6&categories=1,5,12,38,40,41";

export const categoriesArticlesUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&offset=1&_embed&per_page=6&categories=";

export const articleUrlStart = "https://newtov.co.il/wp-json/wp/v2/posts/";
export const ArticleUrlEnd =
  "/?_fields=acf,author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&categories=1,5,12,38,40,41";

export const mosaicArticlesUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&per_page=6&categories=1,5,12,38,40,41&offset=";

export const categoriesPageUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&offset=1&per_page=6&categories=";

export const articlePageMoreUrl =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&offset=1&per_page=6&categories=";

export const moreCatArticlesApi =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&offset=6&per_page=6&categories=";

export const loadMoreCatArticles =
  "https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed&";

export const BASE_URL = "https://newtov.co.il/wp-json/wp/v2";

export const SEARCH_URL = `https://newtov.co.il/wp-json/wp/v2/posts?_fields=author,id,excerpt,title,featured_media,slug,categories,content,date,_links,_embedded&_embed`;

export const POPULAR_POSTS_URL = `https://newtov.co.il/wp-json/wordpress-popular-posts/v1/popular-posts?range=custom`;
