import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import useFetch from "../../customHooks/useFetch";
import CategoryTitle from "../../StyleComponent/CategoryTitle";
import { articleSliderUrl } from "../../api";
import { useStyles } from "./ArticlesSliderStyle";
import LoadingSpinner from "../../StyleComponent/LoadingSpinner";

const ArticlesSlider = () => {
  const classes = useStyles();

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    speed: 1500,
    autoplay: true,
    focusOnSelect: true,
    rtl: true,
    arrows: false,
    dots: false,
    className: classes.root,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { data: articles, isLoading, error } = useFetch(articleSliderUrl);

  return (
    <div className={classes.container}>
      <CategoryTitle text="מבזקים" withArrowTitle={false} />
      {isLoading && <LoadingSpinner />}

      <Slider {...settings}>
        {error && <div>ERROR FROM THE SERVER</div>}
        {articles &&
          articles.map((article, idx) => (
            <Button
              component={Link}
              to={`/articles/${article.id}`}
              key={article.id}
              className={classes.slickItem}
            >
              <div style={{ margin: "10px 15px" }}>
                <Typography variant="subtitle1" color="textSecondary">
                  {article.date}
                </Typography>
                <Typography className="max-text-3-2">
                  <span
                    className="art-slider-text"
                    dangerouslySetInnerHTML={{ __html: article.excerpt }}
                  />
                </Typography>
              </div>
            </Button>
          ))}
      </Slider>
    </div>
  );
};

export default ArticlesSlider;
