import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@material-ui/core";
import PageWrapper from "../PageWrapper";
import MetaTags from "react-meta-tags";
import colors from "../../styles/colors";
import { useStyles } from "../../Components/Categories/CategoriesStyles";
import ArticleBorder from "../../StyleComponent/ArticleBorder";
import LoadingSpinner from "../../StyleComponent/LoadingSpinner";
import useFetch from "../../customHooks/useFetch";
import queryString from "query-string";
import PaginationArticles from "../../Components/PaginationArticles";
import { SEARCH_URL } from "../../api";

const SearchPage = () => {
  const classes = useStyles();
  const { location, push } = useHistory();

  //   // "שומרים על הים: עשרות אלפים ניקו את חופי ישראל"
  //   // "חודש נובמבר: חודש הקניות הגדול או חודש המעשים הטובים?"
  //   // "ג'סטין ביבר יופיע בישראל באוקטובר 2022"

  const query = queryString.parse(location.search);

  const {
    data: articles,
    isLoading,
    error,
    info,
  } = useFetch(
    `${SEARCH_URL}&search=${query?.search}&page=${query.page}&per_page=6`
  );

  const onChangePage = value => {
    query.page = value;
    location.search = queryString.stringify(query);
    push(`${location.pathname}?${location.search}`);
  };

  if (query?.search === "")
    return <p className={classes.emptyMsg}>הבקשה ריקה</p>;

  if (error) return <div> {error} </div>;

  return (
    <PageWrapper videoShow={false} categoryShow={false} articleCat={"חדשות"}>
      <MetaTags>
        <title>{`TVgram | לחפש`}</title>
      </MetaTags>

      <Grid container item xs={12}>
        <div className={classes.searchMsg}>
          {articles?.length > 0 && <p>נמצא לפי בקשה</p>}

          {articles?.length === 0 && (
            <p className={classes.emptyMsg}>שום דבר לא נמצא</p>
          )}
        </div>

        {articles &&
          articles.map((article, idx) => {
            return (
              <Grid item key={idx} style={{ width: "100%" }}>
                <Button
                  style={{
                    color: colors.black,
                  }}
                  className={classes.articleContainer}
                  component={Link}
                  to={{
                    pathname: `/articles/${article.id}`,
                    state: { category: article.category },
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      lg={2}
                      className={classes.cardMediaGrid}
                    >
                      <img
                        src={article.image}
                        alt={article.title}
                        className={classes.cardImage}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={8}
                      lg={10}
                      className={classes.textContainer}
                    >
                      <Typography
                        className="max-text-2"
                        component="h2"
                        variant="h3"
                        dangerouslySetInnerHTML={{ __html: article.title }}
                      />

                      <Typography
                        style={{ fontSize: "16px" }}
                        className="max-text-1"
                        dangerouslySetInnerHTML={{ __html: article.excerpt }}
                      />

                      <Typography className="display-none" variant="subtitle1">
                        {article.author}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>

                <ArticleBorder />
              </Grid>
            );
          })}

        {isLoading && <LoadingSpinner />}

        {articles?.length > 0 && !isLoading && (
          <PaginationArticles
            page={Number(query.page)}
            setPage={onChangePage}
            count={info.totalPages}
          />
        )}
      </Grid>
    </PageWrapper>
  );
};
export default SearchPage;
