import { Grid } from "@material-ui/core";
import React from "react";
import colors from "../../styles/colors";
import ReactPlayer from "react-player";
import "./LiveTv.scss";
import FbComments from "../FbComments/FbComments";

const LiveTv = () => {
  return (
    <Grid xs={12} item style={{ marginTop: "5px" }}>
      {/*<CategoryTitle text="שידור חי" />*/}

      <div className="facebook-live">
        <ReactPlayer
          url={"https://www.facebook.com/tvgram.co.il/videos/918485092106195/"}
          controls={true}
          playing={false}
          config={{
            facebook: {
              appId: "4592693550845209",
            },
          }}
          muted={true}
          style={{
            border: `5px solid ${colors.mercury}`,
            boxSizing: "border-box",
            borderRadius: "5px",
            height: "auto",
          }}
          width="100%"
          height="auto"
        />
      </div>
      {/*<CategoryTitle text="תגובות" />*/}

      <FbComments />
    </Grid>
  );
};
export default LiveTv;
