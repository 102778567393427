import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  adsContainer: {
    margin: '5px auto',
  },
  ads: {
    height: '100%',
    minHeight: '100px',
  },
}));
