const colors = {
  white: "#fff",
  black: "#000",
  mercury: "#e9e9e9",
  mountain_meadow: "#25d366",
  san_marino: "#4267b2",
  red: "#ff0000",
  twitter: "#00acee",
  navyBlue: "#003d79",
  lightBlue: "#1a81c6",
};

export default colors;
