import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "Assistant, sans-serif",
    fontWeightRegular: 400,

    /////AUTHOR
    subtitle1: {
      color: "rgba(0,0,0, 0.6)",
      fontWeight: "400",
      fontSize: "0.9rem",
    },
    author: {
      color: "red",
      fontWeight: "400",
      fontSize: "0.9rem",
    },
    ////////////REGULAR P////////
    body1: {
      fontSize: "20px",
      fontWeight: "400",
      overflowWrap: "break-word",
    },
    h1: {
      fontWeight: "bold",
      fontSize: "3rem",
      overflowWrap: "break-word",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "2.2rem",
      overflowWrap: "break-word",
    },
    h3: {
      fontWeight: "700",
      fontSize: "1.2rem",
    },
    h6: {
      fontWeight: "400",
    },
  },

  palette: {
    primary: {
      main: "#003d79",
    },
    secondary: {
      main: "#CE0000",
    },
    third: {
      main: "#ff0000",
    },

    articleColor: {
      main: "rgba(0, 0, 0, 0.1)",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
