import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import colors from "../../styles/colors";
import { useStyles } from "../Categories/CategoriesStyles";
import ArticleBorder from "../../StyleComponent/ArticleBorder";

const OnePost = ({ article, interest, bgColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.postWrp}>
      <Button
        className={classes.articleContainer}
        component={Link}
        to={{
          pathname: `/articles/${article.id}`,
          state: { category: article.category },
        }}
      >
        <Grid container>
          <Grid item sm={4} xs={5} className={classes.cardMediaGrid}>
            <img
              src={article.image}
              alt={article.title}
              className={classes.cardImage}
            />
          </Grid>

          <Grid item sm={8} xs={7} className={classes.textContainer}>
            <Typography
              className="max-text-2"
              component="h2"
              variant="h3"
              dangerouslySetInnerHTML={{ __html: article.title }}
            />

            <Typography
              style={{ fontSize: "16px" }}
              className="max-text-1"
              dangerouslySetInnerHTML={{ __html: article.excerpt }}
            />

            {!interest && (
              <Typography
                className="display-none"
                style={{
                  color: bgColor === colors.white ? colors.black : colors.white,
                }}
                variant="subtitle1"
              >
                {article.author}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Button>

      <ArticleBorder />
    </div>
  );
};

export default OnePost;
