import React from "react";

import Categories from "../../Components/Categories";
import CategoryTitle from "../../StyleComponent/CategoryTitle";
import { HotLinks } from "../../Components/HotLinks";

import { categoryToNum } from "../../helpers";

import { Grid } from "@material-ui/core";
import LiveTv from "../../Components/LiveTv";
import { articlePageMoreUrl } from "../../api";
import colors from "../../styles/colors";

const PageWrapper = ({
  children,
  articleCat,
  categoryShow = true,
  videoShow = true,
  offset = 0,
}) => {
  return (
    <Grid container item xs={12} lg={9} style={{ margin: "0 auto" }}>
      {children}
      {/* //////////LEFT CONTAINER/////////// */}
      <Grid item md={4} xs={12}>
        {videoShow && <LiveTv />}

        {categoryShow ? (
          <Categories
            hotLinks={<HotLinks />}
            bgColor={colors.navyBlue}
            heading={
              <CategoryTitle name={articleCat} text={"יעניין אותך גם..."} />
            }
            catNum={categoryToNum(articleCat)}
            catApi={articlePageMoreUrl}
            md={12}
            offset={offset}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};
export default PageWrapper;
