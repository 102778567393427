import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  btnWrp: {
    margin: "-15px 0 -5px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  border: {
    position: "absolute",
    top: 18,
    left: "50%",
    width: "95%",
    height: 110,
    border: "1px solid red",
    borderRadius: 4,
    transform: "translateX(-50%)",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:not(:last-child)": {
      marginLeft: 10,
    },
  },
  icon: {
    width: 30,
    height: 30,
  },
}));
