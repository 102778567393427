import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./FooterStyles";
import { Link } from "react-router-dom";
import SocialBtnGroup from "../SocialBtnGroup/SocialBtnGroup";
import { Desktop, Mobile } from "../../common/MediaQueries";
import SocialShare from "../../StyleComponent/SocialShare";
import { closeIsJoin } from "../../redux/layout/layoutSlice";
import { useDispatch } from "react-redux";

const Footer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid className={classes.root} container xs={12} item>
      <Grid className={classes.line} container md={12} xs={12} item>
        <Grid className={classes.space} item>
          <Link className={classes.link} to="/about">
            <Typography to="/about" variant="h6">
              אודות
            </Typography>
          </Link>
        </Grid>

        <Grid className={classes.space} item>
          <Link className={classes.link} to="/contact">
            <Typography to="/about" variant="h6">
              צור קשר
            </Typography>
          </Link>
        </Grid>
      </Grid>

      <Grid className={classes.line} container md={6} xs={12} item>
        <Mobile>
          <SocialBtnGroup
            loinCallback={() => dispatch(closeIsJoin())}
            styleClasses={"btnLight"}
            withTextJoin
            withTextShare
          />
        </Mobile>

        <Desktop>
          <SocialShare />
        </Desktop>
      </Grid>

      <Grid className={classes.line} container xs={12} item>
        <Grid item>
          <Typography
            variant="subtitle1"
            style={{ color: "rgba(255,255,255,0.7)" }}
          >
            <span style={{ display: "block" }}>
              {" "}
              {new Date().getFullYear()}© חדשות טיויגרם בע"מ{" "}
            </span>

            <span
              style={{ color: "rgba(255,255,255,0.7)" }}
              className={classes.link}
              component={Link}
              to="/reporters"
            >
              תקנון האתר
            </span>

            <span style={{ display: "block" }}>
              TVGRAM News Ltd All rights reserved
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Footer;
