import { useState } from "react";
import axios from "axios";

const YOUTUBE_SEARCH_URL = "https://www.googleapis.com/youtube/v3/search";
const YOUTUBE_FETCH_URL = "https://www.googleapis.com/youtube/v3/";
const YOUTUBE_KEY = "AIzaSyD2oyhOFqZMHyGNIH5kK47mBNhkZt98U1Q";
const YOUTUBE_CHANNEL_ID = "UCvjA7--gX-1GDbeFlaiET3Q";

const useFetchYT = () => {
  const [videos, setVideos] = useState([]);

  // `${YOUTUBE_SEARCH_URL}?part=snippet&channelId=${YOUTUBE_CHANNEL_ID}&eventType=live&type=video&key=${YOUTUBE_KEY}`

  const uu = async () => {
    const tt = await axios(
      `${YOUTUBE_SEARCH_URL}?part=snippet&channelId=${YOUTUBE_CHANNEL_ID}&eventType=live&type=video&key=${YOUTUBE_KEY}`
    );
    console.log(tt);
  };

  uu();

  const getVideo = async () => {
    const { data } = await axios(
      `${YOUTUBE_FETCH_URL}channels?id=${YOUTUBE_CHANNEL_ID}&key=${YOUTUBE_KEY}&part=contentDetails&chart=mostPopular`
    );

    if (data?.items) {
      const uploadsID = data.items[0].contentDetails.relatedPlaylists.uploads;

      const { data: videosData } = await axios(
        `${YOUTUBE_FETCH_URL}playlistItems?playlistId=${uploadsID}&key=${YOUTUBE_KEY}&part=snippet&maxResults=6`
      );

      setVideos(videosData.items);
    }
  };

  return { videos, getVideo };
};

export default useFetchYT;
