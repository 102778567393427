import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  root: {
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridBlock: {
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 0px',
    },
  },
  newsContainer: {
    padding: '0px 0 0px 10px',
    margin: '0px 0 10px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0px 0px',
      margin: '0px 0 0px 0',
    },
  },
}));
