import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { useStyles } from "./HotLinksStyles";
import { hotLinks } from "../../common/data/linksJoin";
import Lottie from "react-lottie";
import telegram from "../../assets/lottie/telegram.json";
import whatsapp from "../../assets/lottie/whatsapp-egpid.json";

const tOption = {
  loop: true,
  autoplay: true,
  animationData: telegram,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const waOption = {
  loop: true,
  autoplay: true,
  animationData: whatsapp,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const HotLinks = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="p">שליחת מידע, סרטונים ותמונות</Typography>

      <Box className={classes.btnWrp}>
        <div className={classes.border} />

        <Link
          target="_blank"
          className={classes.button}
          href={hotLinks.telegram.href}
        >
          <Lottie width={`150px`} height={"auto"} options={tOption} />
        </Link>

        <Link
          target="_blank"
          className={classes.button}
          href={hotLinks.wa.href}
        >
          <Lottie width={`150px`} height={"auto"} options={waOption} />
        </Link>
      </Box>
    </Box>
  );
};

export default HotLinks;
