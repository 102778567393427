///Category list navbar

export const categories = [
  "ראשי",
  "חדשות",
  "ספורט",
  "כלכלה",
  "בידור ופנאי",
  "ניוטוב - חדשות חיוביות",
  "מועדון המשפיענים",
];

///modified date string

export const convertDate = isoDate => {
  const date = new Date(isoDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return dt + "/" + month + "/" + year;
};

//modified category name
export const categoryToName = catNum => {
  if (catNum === 1) return "חדשות";
  if (catNum === 5) return "ספורט";
  if (catNum === 12) return "כלכלה";
  if (catNum === 38) return "בידור ופנאי";
  if (catNum === 40) return "מועדון המשפיענים";
  if (catNum === 41) return "חדשות טובות";
  else return "ספורט";
};

export const categoryToNum = catName => {
  if (catName === "חדשות") return 1;
  if (catName === "ספורט") return 5;
  if (catName === "כלכלה") return 12;
  if (catName === "בידור ופנאי") return 38;
  if (catName === "מועדון המשפיענים") return 40;
  if (catName === "חדשות טובות") return 41;
  else return 5;
};

//routes
export const routes = [
  {
    path: "",
    name: "home",
    catTitle: "ראשי",
    catNum: "1",
  },
  {
    path: "/categories/news",
    name: "news",
    catTitle: "חדשות",
    catNum: "1",
  },
  {
    path: "/categories/sport",
    name: "sport",
    catTitle: "ספורט",
    catNum: "5",
  },
  {
    path: "/categories/business",
    name: "business",
    catTitle: "כלכלה",
    catNum: "12",
  },
  {
    path: "/categories/leisure",
    name: "leisure",
    catTitle: "בידור ופנאי",
    catNum: "38",
  },
  {
    path: "/categories/newtov",
    name: "newtov",
    catTitle: "ניוטוב",
    catNum: "41",
  },
  {
    path: "/categories/influencers",
    name: "influencers",
    catTitle: "מועדון המשפיענים",
    catNum: "40",
  },
];

export const findCategoryByPath = pathname =>
  routes.find(i => pathname === i.name);

export const findCategoryByTitle = title =>
  routes.find(i => title === i.catTitle);

////////ads array
export const ads = [
  {
    id: 1,
    title: "פרסומתאחד",

    image:
      "https://www.coolmilk.com/wp-content/uploads/250-newsletter-banner-advert.png",

    categories: "ממומן",
  },
  {
    id: 2,
    title: "פרסומתשתיים",

    image:
      "https://image.freepik.com/free-vector/fashion-sale-social-media-post-template_94047-875.jpg",

    categories: "ממומן",
  },
  {
    id: 3,
    title: "פרסומתשלוש",

    image:
      "https://i.pinimg.com/originals/72/7d/28/727d28810288dc203f280a4f13ad0109.jpg",

    categories: "ממומן",
  },
];

export const adsSingle = {
  id: 3,
  title: "פרסומתשלוש",

  image:
    "https://i.pinimg.com/originals/72/7d/28/727d28810288dc203f280a4f13ad0109.jpg",

  categories: "ממומן",
};

export const artBgColor = [
  "#ff0000",
  "#1417AC",
  "#0C1C3A",
  "#556775",
  "#1457AC",
];

///CUSTOMIZEFETCHDATA
export const customizeFetchData = (item, idx) => {
  return {
    id: item.id,
    author: item._embedded.author[0].name,
    excerpt: item.excerpt.rendered,
    title: item.title.rendered,
    image: item._embedded["wp:featuredmedia"][0].link,
    slug: item.slug,
    category: categoryToName(item.categories[0]),
    content: item.content.rendered,
    date: convertDate(item.date),
    xsIndex: idx >= 2 ? false : true,
    mdIndex: idx === 0 || idx === 1 ? true : false,
  };
};
