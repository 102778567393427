import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AppRouter from "./AppRouter";
import reportWebVitals from "./reportWebVitals";
import { ShareProvider } from "../src/contexts/ShareContext";
import { Provider } from "react-redux";

import store from "./redux/store";

ReactDOM.render(
  <ShareProvider>
    <Provider store={store.store}>
      <AppRouter />
    </Provider>
  </ShareProvider>,
  document.getElementById("root")
);

reportWebVitals();
