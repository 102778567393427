import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CategoryTitle from "../../StyleComponent/CategoryTitle";
import { useStyles } from "./MoreCategoryArticlesStyles";
import CardMedia from "@material-ui/core/CardMedia";
import { Link } from "react-router-dom";
import useFetch from "../../customHooks/useFetch";
import { loadMoreCatArticles } from "../../api";
import PaginationArticles from "../PaginationArticles";
import { getOffset } from "../../helpers/offsetHelper";

const MoreCategoryArticles = ({ catNum, offset = 120, titleText }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const {
    data: article,
    isLoading,
    error,
    info,
  } = useFetch(
    `${loadMoreCatArticles}categories=${catNum}&page=${page}&per_page=6${getOffset(
      offset,
      page
    )}`
  );

  if (isLoading) return <CircularProgress />;
  if (error) return <div>{error}</div>;

  return (
    <>
      <Grid className={classes.moreContainer} item xs={12}>
        <CategoryTitle text={titleText} withArrowTitle={false} />
      </Grid>

      <Grid container>
        {article.map((article, idx) => (
          <Grid key={idx} md={3} xs={6} flex="column" item>
            <Button
              className={classes.articleContainer}
              component={Link}
              to={{
                pathname: `/articles/${article.id}`,
                state: { category: article.category },
              }}
            >
              <Grid container>
                <CardMedia
                  className={classes.cardImage}
                  component="img"
                  alt={article.title}
                  image={article.image}
                  title={article.title}
                />

                <Typography
                  className={`${classes.title} max-text-2`}
                  component="h2"
                  variant="h3"
                  dangerouslySetInnerHTML={{ __html: article.title }}
                />

                <Typography
                  style={{ fontSize: "16px", color: "rgba(0,0,0,0.5)" }}
                  className={"max-text-2"}
                  classes={{ root: classes.hideMobile }}
                  dangerouslySetInnerHTML={{ __html: article.excerpt }}
                />

                <Typography variant="subtitle1" className={classes.hideMobile}>
                  {article.author}
                </Typography>
              </Grid>
            </Button>
          </Grid>
        ))}

        <PaginationArticles
          page={page}
          setPage={setPage}
          count={info?.totalPages}
        />
      </Grid>
    </>
  );
};
export default MoreCategoryArticles;
