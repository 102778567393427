import React from "react";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useStyles } from "./AboutStyles";
import { aboutText } from "../../helpers/texts/aboutText";
import SocialShare from "../../StyleComponent/SocialShare";
import ReactPlayer from "react-player";
import PageWrapper from "../PageWrapper";
import MetaTags from "react-meta-tags";
const AboutPage = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  return (
    <PageWrapper articleCat={"חדשות"}>
      <MetaTags>
        <title>{`TVgram | עלינו`}</title>
      </MetaTags>

      <Grid container item xs={12} md={8} className={classes.aboutContainer}>
        <Typography color="primary" gutterBottom component="h1" variant="h1">
          TVGRAM אתר החדשות
        </Typography>
        <Typography variant="body1">{aboutText}</Typography>
        <div style={{ margin: "10px 0 10px 0" }}>
          <SocialShare
            title={"TVGRAM"}
            url={`https://tvgram.co.il${pathname}`}
          />
        </div>
        <ReactPlayer
          url={"https://www.youtube.com/watch?v=cuUwjiZCR1Y"}
          width="100%"
          height="300px"
          playing={true}
          volume={1}
          muted={true}
          controls
        />
      </Grid>
    </PageWrapper>
  );
};
export default AboutPage;
