import React, { useEffect } from "react";
import { CircularProgress, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import CategoryTitle from "../../StyleComponent/CategoryTitle";

import MainArticle from "../../Components/MainArticle";
import Categories from "../../Components/Categories";

import Ads from "../../Components/Ads/Ads";
import MoreCategoryArticles from "../../Components/MoreCategoryArticles";
import { CategoryGrid } from "../../StyleComponent/CategoryGrid";

import useFetch from "../../customHooks/useFetch";
import { useParams } from "react-router-dom";
import { categoriesPageUrl } from "../../api";
import PageWrapper from "../PageWrapper";
import MetaTags from "react-meta-tags";
import { HotLinks } from "../../Components/HotLinks";
import { useDispatch } from "react-redux";
import { setCategoryName } from "../../redux/categories/categoriesSlice";
import { findCategoryByPath } from "../../helpers";
import VideoSlider from "../../Components/VideoSlider";
import useFetchYT from "../../customHooks/useFetchYT";
import HotArticles from "../../Components/HotArticles/HotArticles";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "0 0 0 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 0px",
    },
  },
  gridBlock: {
    margin: "0px 0px",
  },
  adsSpace: {
    padding: "0 20px 0 0",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 0 0px",
    },
  },

  categoryText: {
    color: "#e65261",
    fontSize: "1rem",
    fontWeight: "800",
  },
}));

const titleColor = "#000";

const CategoryPage = () => {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();

  const categoryData = findCategoryByPath(params.categoryName);

  const { videos, getVideo } = useFetchYT();

  useEffect(() => {
    getVideo();
  }, []);

  useEffect(() => {
    dispatch(setCategoryName(categoryData));
  }, [params]);

  const { isLoading } = useFetch(categoriesPageUrl + categoryData.catNum);

  if (isLoading) return <CircularProgress />;
  return (
    <PageWrapper categoryShow={false} videoShow={false}>
      <MetaTags>
        <title>{`TVgram | קטגוריה`}</title>
      </MetaTags>

      <Grid container item xs={12} md={8} className={classes.root}>
        <Grid item xs={12}>
          <MainArticle articleApi={categoryData.catNum} />
        </Grid>

        <CategoryGrid>
          <Categories
            hotLinks={<HotLinks />}
            heading={
              <CategoryTitle
                text={categoryData.catTitle}
                withArrowTitle={false}
              />
            }
            catNum={categoryData.catNum}
            bgColor={"mainSiteCategory"}
            catApi={categoriesPageUrl}
            md={7}
            offset={1}
          />

          <Ads className={classes.adsSpace} single />
        </CategoryGrid>

        {videos && <VideoSlider videos={videos} isYT />}

        <MoreCategoryArticles
          catNum={categoryData.catNum}
          titleColor={titleColor}
          titleText={`עוד ב${categoryData.catTitle}`}
        />

        <Ads className={classes.adsSpace} single />
      </Grid>

      <HotArticles
        heading={<CategoryTitle text={"כתבות חמות"} withArrowTitle={false} />}
        bgColor={"mainSiteCategory"}
      />
    </PageWrapper>
  );
};
export default CategoryPage;
